<template>
  <span class="price-wrapper">
    <span class="price">
      <span v-if="count"> {{ count }} x </span>
      <span v-if="priceFormatted && !priceString" :id="id" :class="priceClass">
        {{ price ? priceFormatted : reqWords }}
      </span>
      <span v-if="priceString">{{ priceString }}</span>
      <ui-rub v-if="price || priceString" :class="rubClass" :currency="currency"></ui-rub>
    </span>
    <span v-if="price && isDependencyPrice" class="price__question-wrapper">
      <ui-question class="price__question" text="Цена зависит от курса валюты"></ui-question>
    </span>
  </span>
</template>

<script setup lang="ts">
import 'sass/price.sass';

import {computed} from '#imports';
import UiQuestion from '@/components/UI/question.vue';
import UiRub from '@/components/UI/rub.vue';
import {useSpacedPrice} from '@/composables/useUtils';

const props = defineProps({
  count: {type: Number, required: false},
  price: {type: [Number, String], required: false, default: 0},
  priceString: {type: String, required: false, default: ''},
  currency: {type: String, required: false},
  id: {type: String, required: false},
  priceClass: {type: String, required: false},
  rubClass: {type: String, required: false},
  isSpaced: {type: Boolean, required: false, default: true},
  hasPriceWord: {type: Boolean, required: false},
  isDependencyPrice: {type: Boolean, required: false},
});

const reqWords = computed(() => (props.hasPriceWord ? 'Цена по запросу' : 'по запросу'));

const priceFormatted = computed(() =>
  props.isSpaced ? useSpacedPrice(props.price) : String(props.price),
);
</script>
