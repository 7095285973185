<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    enable-background="new 0 0 50 50"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="50"
      height="50"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAB8lBMVEUAAAD/uCr/sin/sSj/
sSj/sin/sSj/sij/sSn/sij/sSj/syj/qiL/vx//sin/sin/sin/sSn/sin/syr/mTP/qhz/sSf/
sin/sin/sSj/tCv/tC3/sSz/sib/sif/sSj/sin/sSj/sSj/sCj/sSn/sin/sij/sib/sCj/sSn/
sSn/sin/sSj/sin/sSj/sSn/sij//wD/sij/sij/qir/tCr/sSj/sSr/qgD/sSn/sin/rSj/sSb/
sij/sSn/syj/tCj/sSj/sij/syX/sSn/sSn/uS7/sij/tiT/sij/sCf/sCn/sSj/tCr/sSj/sin/
sij/sSj/sjP/fwD/sSn/sSn/sin/sSn/tiT/sSj/sSn/sif/ryv/sCj/sir/sij/sij/sSn/rij/
sin/sij/sSj/sin/sSn/sSj/sSj/sin/sSn/sSr/sin/sij/sSj/sSj/sin/qir/riv/syj/sij/
syf/tiT/sSj/tCX/sSn/sij/sSn/syn/sSj/sSn/sSn/sSj/sin/sij/sij/sif/sSj/sij/sin/
sin/sin/sin/sCf/sij/sSj/sSn/sSj/sir/sSj/ryf/vz//sSb/sin/sSn/rS7/ryf/sSn/syn/
sCf/sSj/sCn/syr/sSj/sij/sin/sSj/sin///9puEIzAAAApHRSTlMAElCLtdju+ffohFEPCF26
/fi0WwUJc+a+fSkRFyhTg/zhcEvdy2QUZdPZQ8nHmM1GAdSWBhj1bQN2xRkh3NcsM+DbG8bMC8gV
kjQxkTDDdKOiCgK46nUrDrZcWh1YYWu78RPw88KMT0Xap/4k7HLnq24MI176QAevIuuqmyXjsoCK
0TnBTfsyxGim5Q3OWeT0PM8tBC6CvxYglUpBZm9UUn+z1hN0a24AAAABYktHRKUuuUovAAAACXBI
WXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5wMDCwECacDrKAAAAthJREFUSMfFlulfElEUhgcCmTQ0
CgPFSkVADQQKXDI3QBNTMkkZ0yyXLDLXsml1y7XSNlPbS98/NArBe4cZ+NSv8+m895xn7jmHy51h
mP9kMvkhhTJNxR5WKtIzjqTOV2dmHQVhmmPHtUmB7BO6SJY+R55r0KrzTp46rc8HCgrV0oSxCDCZ
qVosxSVA6ZmosNqENZUBdkfCc5zGs8A51x8XoENuO8orKsU211axOF+diFyoQW2dVMX1DfB4hYi7
Bj6ZdJeWRijdNKK2w9dEJV30UdIQSXBRSBlqBXsIW20ugJ9cM6Jc2IcQYVpYEGvZRaigwpf8NsDW
2rYvA/HDEM+4DDs53ab2WMaVDglErYODIIJX0dkV4rhQVye6g+ITvAYTKXug6416vTpcF0eyYCal
Hn0x9wZuihKyfA11EvsxEJ8sBkUROfSUHkJH3A/cEkWGkUPpRmQyKUyB25QuRtqdFEgYuZQO3sWI
vu9eMmT0oN2oVY71R3618YnJPClEBZdwaco8MR6hVO3VgsD++ZaYyv0HE9MYfUit8dCk6NL7CIOP
yQULnqQapvMpnpE6BKVYWiBAiOeYIWMZmBVDhsARw8M0GZvDvBiygBcHwkAfskUsiSF+DB8IOcgy
ZaplTgxZQb835gdXUUiE1tAtPqaXsBn2O2nFq9fE/NaxIY7I7HjTtWLgQuZGvH1HBIyY4WN+/DaI
Fs61x14x7z8QxOYCJpkEJHZ0Pm5tAz6/g9p9DKU8ibAtgvISrr42lryHELk7GyzJkWYP/FTY5YPJ
QKUIzveUFVaefqJbCZPgn0btYUV4R1iE14OGeimizYPwp8S63atgq0Rf2JtjLKw7TCLCuD6PoOSL
Uwg4jQuR8fCC1VirX0uBkm/U6GRr60DYwUga/70AyN9O/5HrVrsG6n7+WlQBM5M8k8y0Pbsa8oNk
WbGRHPhrnHxrd0/Hqkb3ZueXuNT5/8Z+A3FO/InJ2w1JAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIz
LTAzLTAzVDEwOjAxOjAyKzAxOjAw4pQF7QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMy0wM1Qx
MDowMTowMiswMTowMJPJvVEAAAAASUVORK5CYII="
    />
  </svg>
</template>
